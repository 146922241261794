.collection-modal {
  width: 80vw;
  max-width: 1200px;

  .modal-close-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background: rgba(0,0,0,0);

    &:hover {
      background: rgba(0,0,0,0.2);
      color: #ffffff;
    }
  }
}
