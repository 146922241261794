.animated-bg {
  // background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  background-image: linear-gradient(-20deg, #d558c8 0%, #24d292 100%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.expanded-player-controls {
  text-shadow: 0 0 20px #000000;

  button {
    text-shadow: 0 0 20px #000000;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.pip-button {
  width: unset !important;
  border-radius: 12px !important;
  padding: 0 4px !important;
}

.hide-last-item-margin {
  &:last-child {
    margin-bottom: 0;
  }
}

.playlist-container {
  position: absolute;
  bottom: 90px;
  right: 20px;
  width: 400px;
  max-width: 90vw;
  height: 300px;
  padding: 10px;
  border-radius: 10px;
  z-index: 4;

  &:last-child {
    margin-bottom: 0;
  }
}

.play__button {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 3px solid #ffffff;
  cursor: pointer;

  &:hover {
    border: 3px solid #ffffffcc;

    .button__shape {
      background: #ffffffcc;
    }
  }
}
.button--play {
  border-radius: 50%;
}
.button--play .button__shape {
  width: 8px;
  height: 25px;
  background: #ffffff;
  transition: 0.3s ease-in-out;
}
.button--play .button__shape--one {
  -webkit-clip-path: polygon(0 0, 101% 25%, 101% 75%, 0% 100%);
  transform: translateX(2px);
}
.button--play .button__shape--two {
  -webkit-clip-path: polygon(0 25%, 100% 50%, 100% 50%, 0 75%);
  transform: translateX(1.9px);
}
.button--play.button--active .button__shape--one {
  -webkit-clip-path: polygon(0 15%, 50% 15%, 50% 85%, 0% 85%);
  transform: translateX(0px);
}
.button--play.button--active .button__shape--two {
  -webkit-clip-path: polygon(50% 15%, 100% 15%, 100% 85%, 50% 85%);
  transform: translateX(0px);
}
