@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap");

@font-face {
  font-family: MyFont;
  src: url("./assets/REZ.ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  //background: linear-gradient(180deg, #0984e377 0%, transparent 10%, transparent);
  //background: linear-gradient(90deg, #0984e377 0%, transparent 10%, transparent 90%, #0984e377 100%);
  //background: linear-gradient(
  //  180deg,
  //  #0984e3 0%,
  //  #0984e3 17%,
  //  transparent 17%,
  //  transparent
  //);
}

* {
  font-family: Mukta !important;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

$mainColor: #0984e3;
$mainColorHover: #076bb8;
$buttonColor: #ffffff;

$dangerColor: #ed4c67;
$dangerColorHover: #b53471;

input,
button {
  border-radius: 16px !important;
  outline: none !important;

  &:active,
  &:focus {
    box-shadow: 0 0 2px #777777;
  }
}

select {
  padding: 0 !important;
}

.toast {
  min-height: 50px !important;
  font-size: 14px !important;
  max-width: 300px !important;

  &.toast-danger {
    background: #ed4c67 !important;
  }
}

.dropdown-content {
  right: 0;
  z-index: 101;
}

.slider-wrapper {
  border-radius: 10px;
}

.slider:hover {
  filter: none !important;
  cursor: unset !important;
}
.carousel-slide {
  height: 100%;
  position: relative;
}

.carousel-info {
  position: absolute;
  right: 10px;
  top: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.carousel-info-title {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}

.carousel-info-description {
  padding: 10px;
}

.slider-container {
  width: 100%; /* Width of the outside container */
  min-width: 200px;
  padding: 0 10px;
  display: flex;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 12px; /* Specified height */
  background: #f1f2fa; /* Grey background */
  outline: none; /* Remove outline */
  //opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  //overflow: hidden;

  &:hover {
    filter: opacity(0.8);
    cursor: pointer;
  }
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: $mainColor; /* Green background */
  border: solid 2px #ffffff;
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: $mainColor; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border: solid 2px #ffffff;
  border-radius: 50%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
